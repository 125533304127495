import React from "react";
import { makeStyles } from "../Theme/PortalThemeProvider";
import { useAppContext } from "../appProvider";

const useStyles = makeStyles((theme) => ({
    logo: {
        width: theme.logo.widthMobile,
        marginBottom: theme.spacing(12),
        [theme.breakpoints.up("md")]: {
            width: theme.logo.widthTablet,
        },
        [theme.breakpoints.up("xl")]: {
            width: theme.logo.widthDesktop,
        },
    },
}));

const Logo = () => {
    const classes = useStyles();
    const { siteSettings: settings } = useAppContext();

    if (!settings?.logoUrl) return null;

    return (
        <div className={classes.logo}>
            <a href="/">
                <img src={settings?.logoUrl} alt="Logo" />
            </a>
        </div>
    );
};

export default Logo;
